import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import PublicIcon from "@mui/icons-material/Public";
import { Line } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GavelIcon from "@mui/icons-material/Gavel";
import BallotIcon from "@mui/icons-material/Ballot";
import ComputerIcon from "@mui/icons-material/Computer";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CalendarPicker from "@mui/lab/CalendarPicker";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Chart } from "chart.js/auto";
import endOfWeek from "date-fns/endOfWeek";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import Button from "@mui/material/Button";

// import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Calendar from "react-calendar";
import moment from "moment";
import { CardActionArea } from "@mui/material";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function getIcon(subject) {
  var sub = subject.toLowerCase();
  if (sub.includes("break") || sub.includes("lunch")) {
    return <LocalDiningIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("mechanic")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("social")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("civics")) {
    return <GavelIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("polit")) {
    return <BallotIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("compu")) {
    return <ComputerIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("bio")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("chem") || sub.includes("scien")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("economics") ||
    sub.includes("accou") ||
    sub.includes("busi")
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("geom") ||
    sub.includes("archi") ||
    (sub.includes("engg") && sub.includes("draw"))
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("geo")) {
    return <PublicIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("math")) {
    return <CalculateIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("history")) {
    return <HistoryIcon style={{ fontSize: 45, color: "black" }} />;
  } else {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  }
}

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(weekday[new Date().getDay()]);

  const [isLoading, setIsLoading] = useState(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDay(weekday[date.getDay()]);
  };

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "HOME"
  );

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const state = useSelector((state) => state);

  const [eventsList, setEventsList] = useState([]);
  const [eventsDateList, setEventsDateList] = useState([]);
  const [exams, setExams] = useState([]);

  const [rankersList, setRankersList] = useState([]);

  const [importantDatesList, setimportantDatesList] = useState([]);
  const [importantDatesDateList, setimportantDatesDateList] = useState([]);

  const [holidayList, setHolidayList] = useState([]);
  const [holidayDateList, setHolidayDateList] = useState([]);

  const [examDateList, setExamDateList] = useState([]);

  const [timeTable, setTimeTable] = useState([]);
  const [noticeBoard, setNoticeBoard] = useState({});
  const [chartX, setChartX] = useState([]);
  const [chartY, setChartY] = useState([]);

  const graph = {
    dataLine: {
      labels: chartY,
      datasets: [
        {
          label: "Holistic ranking",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "red",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: chartX,
        },
      ],
    },
  };

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);
    axios.post(API_URL + "/get_events_list", body, config).then((res) => {
      setHolidayList(res.data["holidays_list"]);
      setimportantDatesList(res.data["important_dates_list"]);

      var tempEventDateList = [];
      var tempHolidayDateList = [];
      var tempImportantDateList = [];
      var tempEventsList = res.data["events_list"];
      for (var i = res.data["events_list"].length - 1; i >= 0; i--) {
        if (
          moment(res.data["events_list"][i]["date"], "DD-MM-YYYY").isBefore(
            moment()
          )
        ) {
          tempEventsList.splice(i, 1);
        }
      }

      setEventsList(tempEventsList);
      for (var i = 0; i < res.data["holidays_list"].length; i++) {
        tempHolidayDateList.push(res.data["holidays_list"][i]["date"]);
      }

      for (var i = 0; i < res.data["important_dates_list"].length; i++) {
        tempImportantDateList.push(res.data["important_dates_list"][i]["date"]);
      }

      setEventsDateList(tempEventDateList);
      setimportantDatesDateList(tempImportantDateList);
      setHolidayDateList(tempHolidayDateList);

      var body = {};
      body["institution_id"] = state.auth.user.institution_id;
      body["class_id"] = state.auth.user.class_id;
      body["division"] = state.auth.user.division;
      body = JSON.stringify(body);
      axios
        .post(API_URL + "/get_student_time_table", body, config)
        .then((res) => {
          setTimeTable(res.data);
          axios.post(API_URL + "/get_exams", body, config).then((res) => {
            var tempExamDateList = [];
            var tempExamList = [];
            for (var i = 0; i < res.data.length; i++) {
              if (
                !res.data[i]["completed"] &&
                moment(res.data[i]["start_date"], "DD-MM-YYYY").isAfter(
                  moment()
                )
              ) {
                tempExamList.push(res.data[i]);
              }
            }
            for (var i = 0; i < tempExamList.length; i++) {
              for (var j = 0; j < tempExamList[i]["subjects"].length; j++) {
                var tempSub = tempExamList[i]["subjects"][j];
                tempExamDateList.push(tempSub[Object.keys(tempSub)[0]]["date"]);
              }
            }
            setExams(tempExamList);
            setExamDateList(tempExamDateList);
            axios
              .post(API_URL + "/get_noticeboard_student", body, config)
              .then((res) => {
                setNoticeBoard(res.data);
                setIsLoading(false);
              });
          });
        })
        .catch((err) => {});
    });
  }

  function getContent() {
    if (selectedPage == "HOME") {
      return (
        <Grid container spacing={2}>
          <Grid item md={4}>
            <h4>Timetable</h4>
            {Object.keys(timeTable).length != 0 ? (
              <div style={{ marginTop: "-11px" }}>
                {timeTable[selectedDay] == "" ? (
                  <Box
                    height={60}
                    borderRadius="6px"
                    style={{
                      backgroundColor: colorList[colorIndex],
                      borderBottom: "2px solid " + colorList2[colorIndex],
                      width: "100%",
                      padding: "2px",
                    }}
                  >
                    <div
                      style={{
                        margin: "2px 0px 0px 12px",
                        color: colorList2[colorIndex],
                        fontSize: "20px",
                      }}
                    >
                      No lectures today!
                    </div>
                    <div
                      style={{
                        margin: "-3px 0px 0px 12px",
                        color: colorList2[colorIndex],
                        fontSize: "15px",
                      }}
                    >
                      There are no lectures scheduled for today
                    </div>
                  </Box>
                ) : (
                  <Fragment>
                    {timeTable[selectedDay].map((lecture, index) => (
                      <div style={{ marginBottom: "15px" }}>
                        <Grid
                          container
                          spacing={0}
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid md={2}>
                            <p
                              style={{
                                fontSize: "15px",
                                marginTop: "12px",
                                fontFamily: "Lato-Regular",
                                color: "grey",
                              }}
                            >
                              {Object.keys(lecture)[0]}
                              <span style={{ display: "none" }}>
                                {
                                  (colorIndex = Math.floor(
                                    Math.random() * colorList.length
                                  ))
                                }
                              </span>
                            </p>
                          </Grid>
                          <Grid item md={10}>
                            <Box
                              height={60}
                              borderRadius="10px"
                              style={
                                Object.values(lecture)[0]
                                  .toLowerCase()
                                  .includes("break") ||
                                Object.values(lecture)[0]
                                  .toLowerCase()
                                  .includes("lunch")
                                  ? {
                                      border: "1.5px solid grey",
                                      padding: "2px",
                                      display: "flex",
                                    }
                                  : {
                                      padding: "2px",
                                      display: "flex",
                                      backgroundColor: colorList[colorIndex],
                                    }
                              }
                            >
                              <div
                                style={{
                                  margin:
                                    Object.values(lecture)[0]
                                      .toLowerCase()
                                      .includes("break") ||
                                    Object.values(lecture)[0]
                                      .toLowerCase()
                                      .includes("lunch")
                                      ? "4px 0px 0px 8px"
                                      : "6px 0px 0px 8px",
                                  color: colorList2[colorIndex],
                                }}
                              >
                                {getIcon(Object.values(lecture)[0])}
                              </div>
                              <div>
                                <div
                                  style={{
                                    fontFamily: "Lato-Bold",
                                    fontWeight: "600",
                                    letterSspacing: "2px",

                                    margin:
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("break") ||
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("lunch")
                                        ? "1px 0px 0px 12px"
                                        : "3px 0px 0px 12px",
                                    color:
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("break") ||
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("lunch")
                                        ? "black"
                                        : colorList2[colorIndex],
                                    fontSize: "20px",
                                  }}
                                >
                                  {Object.values(lecture)[0]}
                                </div>
                                <div
                                  style={{
                                    margin: "-3px 0px 0px 12px",
                                    color:
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("break") ||
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("lunch")
                                        ? "black"
                                        : colorList2[colorIndex],
                                    fontSize: "15px",
                                  }}
                                >
                                  {Object.keys(lecture)[0]}
                                </div>
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Fragment>
                )}
                <br></br>
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid item md={4}>
            <h4>Calendar</h4>
            <div style={{ marginTop: "-38px", marginBottom: "2  0px" }}>
              <Box
                borderRadius="16px"
                style={{
                  backgroundColor: colorList[colorIndex],
                  marginTop: "40px",
                }}
              >
                <div className="calendar-container">
                  <Calendar
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                    }}
                    onChange={handleDateChange}
                    value={selectedDate}
                    tileClassName={({ date, view }) => {
                      if (
                        eventsDateList.find(
                          (x) => x === moment(date).format("DD-MM-YYYY")
                        )
                      ) {
                        return "highlightEvent";
                      } else if (
                        holidayDateList.find(
                          (x) => x === moment(date).format("DD-MM-YYYY")
                        )
                      ) {
                        return "highlightHoliday";
                      } else if (
                        importantDatesDateList.find(
                          (x) => x === moment(date).format("DD-MM-YYYY")
                        )
                      ) {
                        return "highlightImportant";
                      } else if (
                        examDateList.find(
                          (x) => x === moment(date).format("DD-MM-YYYY")
                        )
                      ) {
                        return "highlightExam";
                      } else if (date.getDay() == 6) {
                        return "highlightSaturday";
                      }
                    }}
                  />
                </div>
              </Box>
              <div
                style={{
                  padding: 12,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "#6a6fff",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px",
                    marginTop: "2px",
                  }}
                ></div>
                <span
                  style={{
                    marginRight: "25px",
                    color: "grey",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Assignment
                </span>
                <div
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "#a1d99b",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px",
                    marginTop: "2px",
                  }}
                ></div>
                <span
                  style={{
                    marginRight: 15,
                    color: "grey",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Examination
                </span>

                <div
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "red",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px",
                    marginTop: "2px",
                  }}
                ></div>

                <span
                  style={{
                    marginRight: 15,
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  {" "}
                  Holiday
                </span>
                <div
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "#fa9fb5",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px",
                    marginTop: "2px",
                  }}
                ></div>

                <span
                  style={{
                    marginRight: 15,
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  {" "}
                  Event
                </span>
                <div
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "#fdbb84",
                    height: "15px",
                    width: "15px",
                    marginRight: "8px",
                    marginTop: "2px",
                  }}
                ></div>

                <span
                  style={{
                    marginRight: 15,
                    fontSize: "13px",
                    color: "grey",
                  }}
                >
                  {" "}
                  Important
                </span>
              </div>
            </div>
            {eventsList.length != 0 ? (
              <h4 style={{ marginTop: "-15px" }}>Upcoming events</h4>
            ) : (
              <h4 style={{ marginTop: "-15px" }}>No upcoming events.</h4>
            )}

            <div style={{ marginTop: "-15px" }}>
              {eventsList.slice(0, 2).map((event, index) => (
                <Link
                  to="/events"
                  path
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <span style={{ display: "none" }}>
                    {
                      (colorIndex = Math.floor(
                        Math.random() * colorList.length
                      ))
                    }
                  </span>
                  <CardActionArea
                    style={{
                      height: "80px",
                      borderLeft: "5px solid " + colorList3[colorIndex],
                      borderRadius: "5px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        md={8}
                        style={{
                          marginTop: "-3px",
                          marginLeft: "5px",
                        }}
                      >
                        <p
                          style={{
                            margin: "0px 0px 3px 10px",
                            fontFamily: "Lato-Bold",
                            fontSize: "16px",
                          }}
                        >
                          {event["name"]}
                        </p>

                        <p
                          style={{
                            margin: "0px 0px 5px 10px",
                            fontSize: "13px",
                          }}
                        >
                          {moment(event["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </p>
                        <p
                          style={{
                            margin: "-3px 0px 0px 10px",
                            fontSize: "15px",
                            color: colorList2[colorIndex],
                          }}
                        >
                          {event["venue"]}
                        </p>
                      </Grid>
                      <Grid item>
                        <ChevronRightIcon
                          style={{
                            fontSize: "40px",
                            color: colorList3[colorIndex],
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardActionArea>
                </Link>
              ))}
              {eventsList.length >= 2 ? (
                <Link
                  to="/events"
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                      color: "#3182bd",
                    }}
                  >
                    <h4>More Events...</h4>
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
          </Grid>
          <Grid item md={4}>
            <h4>Holistic Ranking</h4>
            <Box style={{ marginTop: "-15px" }}>
              <Line
                height={350}
                width={350}
                data={graph.dataLine}
                options={{
                  responsive: true,
                  animation: { duration: 0 },
                }}
              />
            </Box>
            {exams.length != 0 ? (
              <h4>Upcoming Examinations</h4>
            ) : (
              <h4>No upcoming Examinations.</h4>
            )}{" "}
            <div style={{ marginTop: "-15px" }}>
              {exams.slice(0, 4).map((exam, index) => (
                <Link to="/exams" style={{ textDecoration: "none" }}>
                  <span style={{ display: "none" }}>
                    {
                      (colorIndex = Math.floor(
                        Math.random() * colorList.length
                      ))
                    }
                  </span>

                  <CardActionArea
                    fullWidth
                    style={{
                      borderRadius: "15px",
                      padding: "12px 10px 10px 8px",
                      backgroundColor: colorList[colorIndex],
                      marginBottom: "15px",
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid
                        item
                        md={8}
                        style={{
                          marginTop: "-3px",
                          marginLeft: "5px",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "2px",
                            fontSize: "16px",
                            fontFamily: "Lato-Bold",
                            color: colorList2[colorIndex],
                          }}
                        >
                          {exam["test"]}
                        </div>
                        <div
                          style={{
                            marginBottom: "1px",
                            fontSize: "13px",

                            color: colorList2[colorIndex],
                          }}
                        >
                          {exam["semester"]}
                        </div>
                        <span
                          style={{
                            fontSize: "13px",
                            fontFamily: "Lato-Regular",
                            color: "black",
                          }}
                        >
                          {moment(exam["start_date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          ) +
                            " - " +
                            moment(exam["end_date"], "DD-MM-YYYY").format(
                              "Do MMMM, YYYY"
                            )}
                        </span>
                      </Grid>
                      <Grid item>
                        <ChevronRightIcon
                          style={{
                            fontSize: "40px",
                            color: colorList3[colorIndex],
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardActionArea>
                </Link>
              ))}
            </div>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <div style={{ marginTop: "75px" }}>
          {Object.keys(noticeBoard).map((category) => (
            <Fragment>
              {noticeBoard[category].length != 0 ? (
                <Fragment>
                  <h4>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </h4>
                  <Grid container spacing={1.5}>
                    {noticeBoard[category].map((noti, index) => (
                      <Grid item md={3}>
                        <Box
                          padding={1}
                          borderRadius="5px"
                          sx={{ "&:hover": { opacity: "1 !important" } }}
                          style={{
                            borderRadius: "6px",
                            border: "2px solid #d7ecff",
                            backgroundColor: "white",
                            boxShadow: "none",
                            height: "100%",
                            marginBottom: "5px",
                          }}
                        >
                          <Box style={{ marginLeft: "5px" }}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="space-between"
                            >
                              <Grid item md={10}>
                                <div
                                  style={{
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                    fontSize: "19px",
                                  }}
                                >
                                  {noti["title"]}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontFamily: "Lato-Regular",
                                  }}
                                >
                                  {moment(noti["date"], "DD-MM-YYYY").format(
                                    "Do MMMM, YYYY"
                                  )}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontFamily: "Lato-Bold",
                                  }}
                                >
                                  {noti["venue"]}
                                </div>
                                <div
                                  style={{
                                    margin: "10px 0px 0px 0px",
                                    fontSize: "15px",
                                    fontFamily: "Lato-Regular",
                                  }}
                                >
                                  {noti["desc"]}
                                </div>
                                {Object.keys(noti).includes("link") ? (
                                  <a
                                    href={noti["link"]}
                                    target="_blank"
                                    style={{
                                      margin: "10px 0px 0px 0px",
                                      fontSize: "15px",
                                      fontFamily: "Lato-Regular",
                                    }} rel="noreferrer"
                                  >
                                    View Document
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Fragment>
              ) : (
                <span>
                  <>
                    {category == "everyone" ||
                    category == "parents" ||
                    category == "students" ||
                    category == "teachers" ? (
                      <h4
                        style={{
                          color: "grey",
                          fontSize: 18,
                        }}
                      >
                        No Notice for {category}
                      </h4>
                    ) : (
                      <></>
                    )}
                  </>
                </span>
              )}
            </Fragment>
          ))}{" "}
        </div>
      );
    }
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
      if (Object.keys(JSON.parse(state.auth.user.rank_history)).length != 0) {
        var tempRanking = JSON.parse(state.auth.user.rank_history);
        var tempChartX = [];
        var tempChartY = [];
        for (
          var i = 0;
          i < tempRanking[state.auth.current_year]["history"].length;
          i++
        ) {
          tempChartX.push(0);
          tempChartY.push(0);
          tempChartX.push(
            parseInt(
              tempRanking[state.auth.current_year]["history"][i]["points"]
            )
          );
          tempChartY.push(i + 1);
          setChartX(tempChartX);
          setChartY(tempChartY);
        }
      }
    }
  }, []);

  var colorIndex = 0;

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent={"flex-end"}>
                    <Grid item md={4}>
                      {selectedPage == "HOME" ? (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "NOTICEBOARD" ? (
                        <Button
                          fullWidth
                          value="NOTICEBOARD"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            NOTICEBOARD
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="NOTICEBOARD"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            NOTICEBOARD
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <div
                style={{
                  margin: "37px 20px 0px 90px",
                  backgroundColor: "white",
                }}
              >
                {getContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Dashboard;
